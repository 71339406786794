import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/imaculada.jpg" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/neiker.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Auditor <br></br>de Bem-estar</span> <br></br>
              em Exploração de Perus
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal13} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Immaculada Estevez
                      </div>
                    
                    <div className="titulo inter-light">
                     Consultora, Investigadora e Especialista - NEIKER
                      </div>
                      </div>
                    
                  </div>

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal8} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Xavier Averós
                      </div>
                    
                    <div className="titulo inter-light">
                     Veterinário Especialista, Investigador em bem estar em avicultura - NEIKER
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        30 e 31 de Janeiro, 01 de Fevereiro de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      550 € +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/WQ-perus.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="                https://docs.google.com/forms/d/e/1FAIpQLSdRAIQkPbpiGEawWeq3jFE9bUOIEUidtfcxYo0wJyhj95cejQ/viewform
" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              A rede de especialistas em bem-estra aninal, maneio e instalações Welfare Quality Network e AWIN está a traçar diretrizes e protocolos que garantem o bem-estar animal em pequenos ruminantes com aceitação a nível Europeu pelas entidades retalhistas e compradores. Nesta ótica, se a cadeia produtiva (explorações e matadouros) pretende ultrapassar auditorias e em ultima instancia obter o selo Welfair que certifica o processo terá que implementar alterações que visam o respeito dos protocolos.
                </div>
                <div className="logos">
                  
                <img src={logo5} alt="" />
                

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Os cursos intensivos FarmIN têm um porgrama detalhado que possibilita ao participante observar as diversas áreas abrangidas pelo curso, os diferentes módulos e a atividade dos palestrantes ao longo do dia. 
          <br></br>Assim, a dinâmica do curso possibilita uma aprendizagem intensiva, organizada e coerente dos diferentes módulos abordados.
            </div>
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 30/01/2023 - PRESENCIAL
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">08:30 - 10:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Bases de comportamento e bem-estar em perus</span></p>
                                </div> 
   
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:00 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Esquema de certificação de perus e metodologia de amostra / Protocolo de auditoría de perus de engorda</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:15</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:15 - 16:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Utilização da app i-Watch turkey para recolha de dados de bem-estar animal em exploração</span></p>
                                </div>
   
                                <div class="modulo">
                                  <p><span className="date">16:15 - 17:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:00 - 18:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Resolução de casos práticos e auditorias de exemplo</span></p>
                                </div>

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 31/01/2023 - PRESENCIAL
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">08:30 - 10:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Exercicio prático na exploração I</span></p>
                                </div> 

                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:00 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Exercicio prático na exploração II</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:15</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:15 - 16:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Auditoria na exploração</span></p>
                                </div>
        
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                                <Accordion.Item eventKey="2">
                                <Accordion.Header>
                            
                            +  Dia 3 - 01/02/2023 - PRESENCIAL
                            </Accordion.Header>

                            <Accordion.Body>
                           
                            <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Habilitação de auditores com fins de certificação</span></p>
                                </div> 

     
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                            
                          
          
                    

                                
                               </Accordion.Body>
                               </Accordion.Item>
                          
                             


                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/WQ-perus.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="                https://docs.google.com/forms/d/e/1FAIpQLSdRAIQkPbpiGEawWeq3jFE9bUOIEUidtfcxYo0wJyhj95cejQ/viewform
" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal13} alt="" />

              </div>
              <div className="nome">
              Immaculada Estevez
              </div>
              <div className="titulo">
                 Consultora, Investigadora e Especialista - NEIKER
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Imma certificou 80% de toda a produção espanhola de peru

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
A Inma fez parte de comitês: o Comitê Científico Veterinário, a Seção de Bem-Estar Animal da União Européia em Bruxelas e o IX Grupo de Trabalho (Bem-estar Avícola) da World Poultry Science Association.</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Membro da seção do Comitê Científico de Bem-Estar Avícola da Conferência Mundial de Aves</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Atual Editor da Seção Ambiente, bem-estar e comportamento do Journal of Poultry Science
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Foi conselheiro da American Humane Association e enquanto trabalhava nos Estados Unidos foi conselheiro do National Chicken Council Animal Welfare Program e do Perdue Farms Poultry Welfare Program.</p>
                  
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Co-editor do boletim informativo de extensão Perspectivas de Aves e publicou vários artigos de extensão e colunas de jornal.
                  </p>

                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  
Anteriormente, ela trabalhou e pesquisou com a Swedish Agricultural University, INRA e Maryland University (EUA)
                  </p>

                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  
                  
Inma recebeu mais de 5 prêmios na área avícola e veterinária
                                    </p>
                  
                  </div>
              </div>
            </div>
        </div>
        </div>

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal8} alt="" />

              </div>
              <div className="nome">
              Xavier Averós
              </div>
              <div className="titulo">
                 Veterinário Especialista, Investigador em Bem Estar em Avicultura - NEIKER
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
Fue colaborador de INRA (Francia) e na Universidad de Extremadura.</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Grande parte dos projetos está relacionado ccon el desarrollo de indicadores de bienestar para distintas espécies avícolas, como con tecnologias de monitorização de dichos indicadores.</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Interveniente no desarrollo do protocolo de certificação da bienestar no pavos de engorde na base da metodologia AWIN</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Xavi está estado / está implicado em um total de 14 Proyectos y Contratos de Investigación, 6 de ellos de escala UE, siendo co-PI em um Projeto de investigação e PI de um Contrato de Investigación.
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  
Xavi está implicado na ação COST GroupHouseNet, que incluiu 31 países COST e se centrou na redução do número de plumas em galinhas de puesta.</p>
                 
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Xavi também está envolvido nos Comités Ejecutivo e Organizador do Congresso Internacional da Sociedade Internacional de Etologia Aplicada (ISAE, Vitoria-Gasteiz, julho de 2014).
                  </p>

                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Xavi foi co-autor da parte de mais de 60 publicações na área do bem estar.
                  </p>
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>
  
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
